import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { RadioInputData } from '~/ui/components/RadioGroup';
import { RadioGroup } from '~/ui';
import { DuplicateTaskModalFormProps, ItemTypes } from '../types';
import { DuplicateTaskModalInvoices } from '../DuplicateTaskModalInvoices';
import './DuplicateTaskModalChooseItems.scss';
import generalUtils from '~/utils/general-utils';

export const DuplicateTaskModalChooseItems = () => {
    const rootClassName = 'duplicate-task-modal-choose-items';
    const { t } = useTranslation('duplicateTaskModal');
    const { values } = useFormikContext<DuplicateTaskModalFormProps>();
    const isFeatureFlagEnabled = generalUtils.isFlaggedFeatureDisplayed();

    const radioData: RadioInputData<ItemTypes>[] = [
        {
            id: `${rootClassName}-${ItemTypes.INVOICE}`,
            label: t('items.invoices'),
            value: ItemTypes.INVOICE
        }
    ];

    /**
     * @todo remove local feature flag
     */
    if (isFeatureFlagEnabled) {
        radioData.push({
            id: `${rootClassName}-${ItemTypes.INVENTORY}`,
            label: t('items.inventoryItems'),
            value: ItemTypes.INVENTORY,
            disabled: true
        });
    }

    return (
        <div className={`${rootClassName} _fd-column`}>
            <div className={`${rootClassName}__header _fd-column`}>
                <span className={`${rootClassName}__title`}>
                    {t('items.title')}
                </span>
                <span className={`${rootClassName}__description`}>
                    {t('items.description')}
                </span>
                <RadioGroup
                    className={`${rootClassName}__radio`}
                    radioData={radioData}
                    value={values.itemType}
                    name="itemType"
                />
            </div>
            <DuplicateTaskModalInvoices />
        </div>
    );
};
