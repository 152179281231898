import { useCallback } from 'react';
import { ApiTask } from '~/api/types';
import {
    ActionTypes,
    AssignmentTypes,
    DuplicateTaskModalFormProps,
    ItemTypes
} from '../../types';
import { useSplitTaskByInvoice } from './useSplitTaskByInvoice';

interface UseDuplicateTaskModalContextProviderProps {
    apiTask?: ApiTask;
    onClose: () => void;
}

export const useDuplicateTaskModalContextProvider = ({
    apiTask,
    onClose
}: UseDuplicateTaskModalContextProviderProps) => {
    const { splitTaskByInvoice } = useSplitTaskByInvoice();

    const onSubmit = useCallback(
        async (values: DuplicateTaskModalFormProps) => {
            const { itemType, actionType, assignmentType } = values;

            const isSplitTaskByInvoice =
                itemType === ItemTypes.INVOICE &&
                actionType === ActionTypes.MOVE_INVOICE_OR_INVENTORY &&
                assignmentType === AssignmentTypes.CREATE_TASK;

            if (!apiTask || !isSplitTaskByInvoice) return;

            const { invoicesIds: selectedInvoicesIds } = values;
            await splitTaskByInvoice({
                apiTask,
                onClose,
                selectedInvoicesIds
            });
        },
        [apiTask, onClose, splitTaskByInvoice]
    );

    return {
        onSubmit
    };
};
