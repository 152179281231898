import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { uniq } from 'lodash';
import { DuplicateTaskModalInvoiceProps } from './types';
import { Checkbox } from '~/ui';
import { DuplicateTaskModalInventory } from '../DuplicateTaskModalInventory';
import { useDuplicateTaskModalInvoice } from './useDuplicateTaskModalInvoice';
import './DuplicateTaskModalInvoice.scss';

export const DuplicateTaskModalInvoice = ({
    inventory,
    invoice,
    invoiceRenderProps
}: DuplicateTaskModalInvoiceProps) => {
    const rootClassName = 'duplicate-task-modal-invoice';
    const { t } = useTranslation('duplicateTaskModal');
    const {
        amountDue,
        invoiceId,
        invoiceNumber,
        weight,
        labels,
        size: volume
    } = invoice;

    const hasInventory = inventory && inventory.length > 0;

    const { handleToggleCheckBox, invoicesIds, isSelected } =
        useDuplicateTaskModalInvoice({
            invoiceRenderProps,
            invoiceId
        });

    const elementClassName = classNames(rootClassName, '_fd-column', {
        [`${rootClassName}--selected`]: isSelected
    });

    const volumeText = volume
        ? t('invoiceCard.volume', {
              volume
          })
        : null;
    const weightText = weight
        ? t('invoiceCard.weight', {
              weight
          })
        : null;
    const weightAndVolumeText = [weightText, volumeText]
        .filter(Boolean)
        .join(' • ');

    const labelsForDisplay = useMemo(() => {
        const uniqueLabels = uniq(labels);

        if (!uniqueLabels.length) return [];

        const allowedNumberOfVisibleLabels = 4;

        const visibleLabels = uniqueLabels.slice(
            0,
            allowedNumberOfVisibleLabels
        );
        const otherLabelsCount =
            uniqueLabels.length - allowedNumberOfVisibleLabels;

        if (otherLabelsCount < 1) {
            return visibleLabels;
        }

        return [...visibleLabels, `${otherLabelsCount}+`];
    }, [labels]);

    const renderLabels = () => {
        if (!labelsForDisplay.length) return null;

        return (
            <div className={`${rootClassName}__labels _d-flex`}>
                {labelsForDisplay.map((label) => (
                    <span key={label} className={`${rootClassName}__label`}>
                        {label}
                    </span>
                ))}
            </div>
        );
    };
    return (
        <div
            className={elementClassName}
            key={invoiceId}
            data-testid={rootClassName}
        >
            <div
                className={`${rootClassName}__select-invoice _d-flex`}
                data-testid={`${rootClassName}__select-invoice`}
            >
                <Checkbox
                    id={invoiceId}
                    value={invoiceId}
                    handleChange={handleToggleCheckBox}
                    selectedValues={invoicesIds}
                    className={`${rootClassName}__checkbox`}
                    data-testid={`${rootClassName}__checkbox--${invoiceId}`}
                />
                <div
                    className={`${rootClassName}__details _fd-column`}
                    data-testid={`${rootClassName}__details`}
                >
                    <div
                        className={`${rootClassName}__invoice _jc-space-between`}
                        data-testid={`${rootClassName}__invoice`}
                    >
                        <span
                            className={`${rootClassName}__amount-due`}
                            data-testid={`${rootClassName}__amount-due`}
                        >
                            {t('invoiceCard.amountDue', {
                                amountDue
                            })}
                        </span>
                        •
                        <span
                            className={`${rootClassName}__invoice-number`}
                            data-testid={`${rootClassName}__invoice-number`}
                        >
                            {t('invoiceCard.invoiceNumber', {
                                invoiceNumber
                            })}
                        </span>
                    </div>
                    {Boolean(weightAndVolumeText) && (
                        <span
                            className={`${rootClassName}__units`}
                            data-testid={`${rootClassName}__units`}
                        >
                            {weightAndVolumeText}
                        </span>
                    )}
                    {renderLabels()}
                </div>
            </div>
            {hasInventory && (
                <div
                    className={`${rootClassName}__inventory _fd-column`}
                    data-testid={`${rootClassName}__inventory`}
                >
                    {inventory.map((inventoryItem) => (
                        <DuplicateTaskModalInventory
                            key={inventoryItem.item_id}
                            inventory={inventoryItem}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
